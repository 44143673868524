<template>
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="80" height="80" rx="40" fill="#656B83" fill-opacity="0.08" />
    <path d="M34.75 38.2857H31.3V46.2857H34.75V38.2857Z" fill="#656B83" />
    <path d="M42.225 38.2857H38.775V46.2857H42.225V38.2857Z" fill="#656B83" />
    <path d="M52 48.5714H29V52H52V48.5714Z" fill="#656B83" />
    <path d="M49.7 38.2857H46.25V46.2857H49.7V38.2857Z" fill="#656B83" />
    <path d="M40.5 28L29 33.7143V36H52V33.7143L40.5 28Z" fill="#656B83" />
  </svg>
</template>
